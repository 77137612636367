import React from 'react';
import {graphql, Link, StaticQuery} from 'gatsby';

import Graphic from 'components/Graphic.js';
import Hero from 'components/Hero.js';
import Layout from 'components/Layout.js';
import Meta from 'components/Meta.js';
import Tile from 'components/Tile.js';
import i18n from 'data/i18n.en.json';

export default class Careers extends React.Component {
    render() {
        return (
            <StaticQuery
                query={graphql`
                    {
                        pageDetails: wordpressPage(wordpress_id: {eq: 108}) {
                            acf {
                                subtitle
                                title
                            }
                            content
                            title
                            wordpress_id
                        }
                        jobs: allWordpressWpCareers(
                            filter: {wordpress_id: {ne: 2223}}
                            sort: {fields: date}
                        ) {
                            edges {
                                node {
                                    acf {
                                        location
                                    }
                                    path
                                    title
                                    wordpress_id
                                }
                            }
                        }
                        blogLink: wordpressCategory(
                            wordpress_id: {eq: 106132}
                        ) {
                            path
                        }
                    }
                `}
                render={(data) => (
                    <Layout className="contain">
                        <Meta
                            description={data.pageDetails.acf.subtitle}
                            title={data.pageDetails.title}
                        />
                        <Hero
                            subtitle={data.pageDetails.acf.subtitle}
                            title={data.pageDetails.acf.title}
                        />
                        {data.pageDetails.wordpress_id && (
                            <nav className="tiles">
                                <Tile
                                    copy={i18n.aboutTiles.aboutUs}
                                    icon="iconAbout"
                                    title={i18n.aboutTiles.aboutUsTitle}
                                    to="/company/about"
                                />
                                <Tile
                                    copy={i18n.aboutTiles.leadership}
                                    icon="iconLeadership"
                                    title={i18n.aboutTiles.leadershipTitle}
                                    to="/company/leadership/"
                                />
                                <Tile
                                    copy={i18n.aboutTiles.blog}
                                    icon="iconBlog"
                                    title={i18n.aboutTiles.blogTitle}
                                    to={data.blogLink.path}
                                />
                            </nav>
                        )}
                        {data.pageDetails.content && (
                            <section
                                className="blocks"
                                dangerouslySetInnerHTML={{
                                    __html: data.pageDetails.content,
                                }}
                            />
                        )}
                        <section className="job-listing">
                            {data.jobs.edges.length ? (
                                <>
                                    <h2>{i18n.careers.vacancies}</h2>
                                    <ul>
                                        {data.jobs.edges.map(({node}) => (
                                            <li key={node.wordpress_id}>
                                                <Link to={node.path}>
                                                    <p>
                                                        {node.title}
                                                        <span>
                                                            {node.acf.location}
                                                        </span>
                                                    </p>
                                                    <Graphic name="iconArrowRight" />
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            ) : (
                                <p>{i18n.careers.noVacancies}</p>
                            )}
                        </section>
                    </Layout>
                )}
            />
        );
    }
}
